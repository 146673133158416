<template>
  <div
    class="chat-input-box"
    @click="editChat()"
  >
    <van-field
      v-model="chatVal"
      placeholder="快来和我聊天吧"
    />
    <van-button
      class="send-text"
      @click="sendText"
    >发送</van-button>
  </div>
</template>

<script>
// import { agentDialog } from "@/fetch/api/chat.js";
import Config from '@/fetch/config'
export default {
  props: {
    pageType: {
      type: String,
      default: "", //1首页，2对话页
    },
  },
  components: {},
  data() {
    return {
      chatVal: "",
      hebaoStatus: "",
    };
  },
  computed: {},
  methods: {
    editChat() {
      if (this.$route.path == "/home") {
        // let question = {
        //   broker_id: localStorage.getItem("broker_id"),
        //   content: "退出核保",
        //   first_conversation: "0",
        //   speaker: 1,
        //   is_collected: "0",
        //   online_data_id: null,
        //   hebao_answer: {},
        //   reset_disease: -1,
        //   hebao: 0,
        //   diseaseCode: {},
        // };
        // agentDialog(question).then((res) => {
        //   if (res.code == 200) {
        //     this.hebaoStatus = res.result.content.hebao;
        //   }
        // });
      }
      localStorage.setItem("hideInput", true);
      //   this.$emit("handleChat", "/chat/moment");
      //let user_id = this.$route.query.userId
      let username = this.$route.query.username
      let mobile = this.$route.query.mobile
      let jybToken = this.$route.query.jybToken
      let userId = this.$route.query.userId
      window.location.href = `${Config.aiUrl}?mobile=` + mobile + `&username=` + username + `&source=YZT&jybToken=` + jybToken + '&userId=' + userId
    },
    sendText() {
      this.$emit("sendText", this.chatVal);
      this.chatVal = "";
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
input::-webkit-input-placeholder {
  color: #d0d0d0;
}
.chat-input-box {
  position: fixed;
  left: 5.6%;
  bottom: 4.43%;
  width: 88.93%;
  height: 1.07rem;
  background: #fff;
  border-radius: 0.53rem;
  overflow: hidden;
  .van-field {
    width: calc(100% - 2.33rem);
    height: 1.07rem;
    display: flex;
    input {
      align-items: center;
    }
  }
  .send-text {
    position: absolute;
    right: 0rem;
    top: 0rem;
    width: 2.33rem;
    height: 1.07rem;
    // background: #FA6B7E;
    border-radius: 0.56rem;
    border: none;
    font-size: 0.4rem;
    font-weight: 600;
    color: #ffffff;
    line-height: 0.56rem;
    background: linear-gradient(180deg, #2de2e6 0%, #2291f7 100%);
  }
  input::-webkit-input-placeholder {
    color: #5f78cf;
    font-size: 0.4rem;
    line-height: 0.6rem;
  }
  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #5f78cf;
    font-size: 0.4rem;
  }
  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #5f78cf;
    font-size: 0.4rem;
  }
  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #5f78cf;
    font-size: 0.4rem;
    height: 1.07rem;
  }
}
</style>
